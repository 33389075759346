<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools/shipping.title-tracking')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="checkTrackingNumber" name="checkTrackingNumberForm">
        <div class="form-group row">
            <label for="shippingProvider" class="col-lg-3 col-form-label">{{$t('tools/shipping.info.provider')}}</label>
            <div>
              <div v-if="listShippingProviders.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="listShippingProviders.length > 0" id="provider" :placeholder="$t('tools/shipping.info.provider_placeholder')" v-model="form.provider" :options="listShippingProviders" label="name" :reduce="provider => provider.code"></v-select>
            </div>
        </div>
        <div class="form-group row">
          <label for="shippingTrackingNumber" class="col-lg-3 col-form-label">{{ $t('tools/shipping.info.trackingNumber') }}</label>
          <div class="col-lg-9">
            <input id="shippingTrackingNumber" type="text" :placeholder="$t('tools/shipping.info.trackingNumber_placeholder')" class="form-control" v-model="form.trackingNumber" required>
          </div>
        </div>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('tools/shipping.form.button') }}</button>
      </form>

      <div class="row results" v-if="shippingResult && shippingResult.packages">
        <div class="col-md-auto" v-for="(result, index) in shippingResult.packages" :key="index">
          <div :class="'panel ' + (result.status == 'D' ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ $t('tools/shipping.status.'+result.status) }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm" >{{ $t('tools/shipping.results.trackingNumber') }}</div>  
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.trackingNumber }}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class ShipTracking extends Vue {

  form:any = {
    provider: null,
    trackingNumber:null
  };

  listShippingProviders:any[] = [];

  laddaSubmit:Ladda.LaddaButton|null = null;

  shippingResult:any = null;

  created() {
    this.updateShippingProviderList();
  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkTrackingNumberForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  updateShippingProviderList() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/shop/delivery/shipping-providers', options).then((response:any) => {
      if(response.shippingProviders) {  
        this.listShippingProviders = response.shippingProviders;
      }
    });
  }

  checkTrackingNumber(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "provider" : this.form.provider,
      "trackingNumber" : this.form.trackingNumber
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/shipping/checkTrackingNumber', input, options).then((response:any) => {
      
      this.shippingResult = response.trackResponse;

      this.laddaSubmit!.stop();
    });
  }

}
</script>